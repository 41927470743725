
.App {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
  font-size: calc(5px + 2vmin);
  color: white;
}
